@import 'assets/styles/mixins.scss';

//////////////////////////////////////////////////////////////////////////////////////////
/* ERRORS */

.errors {
  width: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.newButtonForlist {
  font-size: 14px !important;
  height: 2.68rem !important;
  width: 7.12rem !important;
  background: #089ea4 !important;
  border: 0.5px solid #07888d !important;
  border-radius: 2px !important;
  color: #ffffff !important;
  padding: 5px 0px 0px 12px !important;
  opacity: 1;
  font-weight: 600 !important;
}

.container {
  max-width: rem(505);
  width: 100%;
}

.containerForError{
  max-width: rem(505);
  width: 100%;
}

.footerNav {
  li {
    margin: rem(5) rem(14);
  }
}
