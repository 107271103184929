.ant-breadcrumb-separator {
  margin: 0 5px !important;
  color: #6d9eeb !important;
}

.body .ant-select {
  font-family: inherit !important;
}

.react-pdf__Page__canvas{
  width: -webkit-fill-available !important
}

.ant-form-item-required::before {
  float: right !important;
  margin-right: -8px !important;
  font-weight: 600 !important;
}

.ant-modal-confirm .ant-modal-body {
  padding: 15px 15px 15px !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 'none' !important;
}
.ant-form-vertical .ant-form-item-label {
  padding: 0px !important;
}

.ant-modal-header {
  padding: 0px !important;
}

.alert.alert-info {
  background-color: #c13832 !important;
}

.alert {
  padding: 0px !important;
}

.alert .close {
  color: black !important;
  opacity: 0.5;
  outline: none !important;
  width: 30px;
  height: 30px;
  border-radius: 7px;
  padding: 0px;
  background-color: white;
  font-size: 28px !important;
  margin: 6px;
}

.alert-dismissible .close {
  padding: 0px !important;
}

/* .air__utils__content{
  padding: 0px !important;
  background-color: #0C4DA9 !important;
  max-width: 100% !important;  
} */
.hmt {
  padding: 0%;
}
.ant-checkbox-inner {
  border: 1px solid #d3d3d3 !important;
}

.ant-layout-content {
  background-color: #0c4da9 !important;
}

.ant-collapse > .ant-collapse-item {
  border: none !important;
}

.MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 21px !important;
  margin: 0;
  display: block;
  padding: 0px !important;
  min-width: 0;
  background: white !important;
  box-sizing: content-box;
  animation-name: none !important;
  letter-spacing: none !important;
  animation-duration: none !important;
  -webkit-tap-highlight-color: none !important;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}
.MuiInput-underline:after {
  border-bottom: none !important;
}

.ant-tabs-ink-bar ant-tabs-ink-bar-animated {
  width: 0px !important;
}

.ant-tabs-ink-bar {
  bottom: 0px !important;
  background-color: transparent !important;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  height: 40px !important;
  color: #0c4da9 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  background: #fff !important;
  border-color: #e8e8e8 !important;
  border-bottom: 1px solid #fff !important;
}

/* .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
  border: none !important;
  font-size: 12px !important;
  padding: 0px 30px 0px 7px !important;
  color: #464646;
}  */
.ant-tabs.ant-tabs-card .ant-tabs-tab {
  border: none !important;
  font-size: 12px !important;
  padding: 0px 13px 0px 13px !important;
  color: #464646;
  background: #f2f4f8 !important;
}

.ant-tabs-bar {
  margin: 0px !important;
  border-bottom: none !important;
}
.ant-tabs-tab-prev,
.ant-tabs-tab-next {
  color: white !important;
}

.ant-tabs.ant-tabs-card .ant-tabs-extra-content {
  line-height: 30px !important;
  margin: 2px !important;
}

.ant-input {
  color: #0c0c0d !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.ant-time-picker-input {
  font-weight: 400 !important;
}
.ant-select-auto-complete.ant-select .ant-input {
  color: #0c0c0d !important;
  font-size: 12px !important;
  font-family: Open Sans !important;
  font-weight: 400 !important;
}
.ant-form-item-label > label {
  font-size: 12px !important;
  color: #5d5454 !important;
  font-weight: 300 !important;
}
/* label {
  display: inline-block !important;
  margin-bottom: 0rem !important;
} */
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: -14px !important;
  left: 11px !important;
  color: #373739 !important;
  font-size: 10px !important;
  font-weight: 600 !important;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-size: 12px !important;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  padding: 1px !important;
}

.ant-tabs-tab-active .ant-tabs-tab {
  margin-right: 1rem !important;
}

.ant-select-dropdown-menu-item-selected {
  background-color: whitesmoke !important;
  color: #1155cc !important;
  font-family: Open Sans !important;
}

.style_searchInput__1uxJS {
  border: none !important;
}
.ant-select-dropdown-menu-item {
  font-size: 12px !important;
  font-family: Open Sans !important;
}

.ant-form-item {
  margin-bottom: 10px !important;
}
/* .ant-form-item-children {
  position: relative;
  bottom: 17px !important;
} */
.ant-form-item-label {
  line-height: unset !important;
}
.ant-form-item-control {
  line-height: 25px !important;
}

.ant-form-vertical .ant-form-item {
  padding-bottom: 0px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff !important;
  /* border-color: #707070 !important; */
  width: 19px !important;
  height: 19px !important;
}

.ant-checkbox-inner {
  width: 19px !important;
  height: 19px !important;
}
.ant-form-item-required::before {
  margin-right: 0px !important;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  border-top-color: initial !important;
  border-top-style: initial !important;
  border-top-width: 0px !important;
  border-right-color: #161417 !important;
  border-right-style: solid !important;
  border-right-width: 2px !important;
  border-bottom-color: #161417 !important;
  border-bottom-style: solid !important;
  border-bottom-width: 2px !important;
  border-left-color: initial !important;
  border-left-style: initial !important;
  border-left-width: 0px !important;
  border-image-source: initial !important;
  border-image-slice: initial !important;
  border-image-width: initial !important;
  border-image-outset: initial !important;
  border-image-repeat: initial !important;
}
.ant-timeline-item {
font-size: 12px !important;
}
.ant-input {
  font-size: 13px !important;
  color: #000000 !important;
 /*  top: -3px !important; */
  font-family: Open Sans !important;
}
.ant-select {
  font-size: 13px !important;
  color: #000000 !important;
  top: -1px !important;
  font-family: Open Sans !important;
}

/* .ant-select-selection-selected-value{
  font-family: Open Sans !important;
  color:#000000;

} */
.ant-form-item .ant-switch {
  top: -8px !important;
}

/*  ag grid all css*/

.ag-row-hover {
  /* putting in !important so it overrides the theme's styling as it hovers the row also */
  background-color: #dfdfff !important;
}

.ag-theme-balham .ag-input-wrapper input:not([type]),
.ag-theme-balham .ag-input-wrapper input[type='text'],
.ag-theme-balham .ag-input-wrapper input[type='number'],
.ag-theme-balham .ag-input-wrapper input[type='tel'],
.ag-theme-balham .ag-input-wrapper input[type='date'],
.ag-theme-balham .ag-input-wrapper input[type='datetime-local'] {
  border-width: 0px !important;
}
.ag-theme-balham .ag-cell.ag-cell-inline-editing {
  background-color: white !important;
}
.ag-theme-balham .ag-header {
  background-color: white !important;
  font-weight: 500 !important;
  color: #607d8b !important;
  font-family: 'Open Sans' !important;
  border-bottom: 1px solid #f0f0f0 !important;
}

.ag-theme-balham .ag-row {
  border-color: #f0f0f0 !important;
}
.ag-theme-balham .ag-root {
  border: 1px solid #f0f0f0 !important;
}
.ag-row-hover {
  background-color: #eeeeee !important;
}
.ag-theme-balham .ag-row-selected {
  background-color: white !important;
  border-color: #d9dcde !important;
}

.ag-cell-editor-autocomplete-input {
  height: 26px !important;
  width: 100% !important;
  background-repeat: no-repeat;
  text-indent: 20px;
  background-size: 1em 1em;
  background-position: 15em 5px;
}

.ag-theme-balham {
  color: #19181a !important;
  font-weight: 400 !important;
  font-family: 'Open Sans' !important;
}

.ag-cell-editor-autocomplete-input {
  height: 26px !important;
  width: 100% !important;
  background-repeat: no-repeat;
  text-indent: 0px !important;
  background-size: 1em 1em;
  background-position: 15em 5px;
}

.ag-theme-balham .ag-cell.ag-cell-inline-editing {
  height: 27px !important;
  padding: 1.5px !important;
}
.ag-theme-balham .ag-menu,
.ag-theme-balham .ag-theme-balham.ag-dnd-ghost,
.ag-theme-balham .ag-cell.ag-cell-inline-editing,
.ag-theme-balham .ag-popup-editor,
.ag-theme-balham .ag-select-agg-func-popup,
.ag-theme-balham .ag-overlay-loading-center {
  border: 1px solid #f0f0f0 !important;
  border-top: none !important;
}

.ag-theme-balham .ag-row.ag-row-last {
  border-bottom-width: 0.5px !important;
}

/* css by mamatha */

.ant-modal-footer {
  padding: 10px 10px;
  text-align: right;
  background: transparent;
  border-radius: 0 0 4px 4px;
  background-color: #ffffff !important;
}

.ant-modal-body {
  /* padding: 12px !important; */
  font-size: 12px;
  line-height: 1.5;
  background-color: #ffffff !important;
  word-wrap: break-word;
}

.ant-modal-mask {
  border-color: black !important;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;

  background-color: rgba(51,51,51,48%) !important;
  filter: alpha(opacity=50);
}

.rowEditDetails {
  border: 0px !important;
  width: 110% !important;
}
.collapseClassStyle {
  /* // background: #f7f7f7; */
  border-radius: 2px;
  margin-bottom: 24px;
  border: 0px;
  overflow: hidden;
  /*  background: #f7f7f7; */
}

.react-datepicker-popper[data-placement^='bottom'] {
  margin-top: -27px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 73vh;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
.ant-table {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10 !important;
  color: #19181a !important;
  font-size: 12px !important;
  font-variant: tabular-nums;
  line-height: 0.1 !important;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  clear: both;
}
.ant-table-thead > tr > th {
  padding: 7px 8px !important;
  overflow-wrap: break-word;
  font-weight: 300 !important;
  color: #607d8b !important;
  font-family: 'Open Sans' !important;

  font-family: 'open sans';
}
.ant-table-tbody > tr > td {
  padding: 7px 8px !important;
  overflow-wrap: break-word;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td{
  padding: 15px 8px !important;
}


.react-datepicker-customeClass{
  -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    height: 32px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}