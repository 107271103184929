.ant-popover-placement-bottom, .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight {
    padding-top: 15px !important;
}
.ant-popover-arrow{
    width: 20px !important;
    height: 20px !important;
    background: white !important;
    
}
.ant-dropdown{
    position: fixed !important;
}

